import { useEffect, useRef, MutableRefObject } from "react";

/**
 * Hook that changes cursor for MouseEvent based on mouse key
 * @param canvasRef reference to HTMLCanvasElement
 */

const useHandleCanvasCursor = (canvasRef: MutableRefObject<HTMLCanvasElement | null>): void => {
  const timeout = useRef<ReturnType<typeof setTimeout>>(null!);

  useEffect(() => {
    const handleCursor = (e: MouseEvent) => {
      if (e.button === 0) {
        timeout.current = setTimeout(() => {
          (e.target as HTMLCanvasElement).style.cursor = "grabbing";
        }, 100);
      }
      if (e.button === 1) {
        (e.target as HTMLCanvasElement).style.cursor = "move";
      }
    };

    const defaultCursor = (e: MouseEvent) => {
      clearTimeout(timeout.current);
      (e.target as HTMLCanvasElement).style.cursor = "inherit";
    };

    canvasRef?.current?.addEventListener("mousedown", handleCursor);
    canvasRef?.current?.addEventListener("mouseup", defaultCursor);

    return () => {
      canvasRef?.current?.removeEventListener("mousedown", handleCursor);
      canvasRef?.current?.removeEventListener("mouseup", defaultCursor);
    };
  }, []);
};

export default useHandleCanvasCursor;

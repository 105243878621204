import axios from "axios";

import { ParsedQueryValue, OpacityViewerDataResponse, OpacityEntity } from "../types";
import { remapOpacityEntity } from "../utils";

const api = axios.create({
  timeout: 2500,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const getModels = async (entitiesId: ParsedQueryValue): Promise<{ entities: OpacityEntity[] }> => {
  try {
    const response = await api.get<OpacityViewerDataResponse>(entitiesId as string);
    const {
      data: { model },
    } = response.data;

    return { entities: model.map(model => remapOpacityEntity(model)) };
  } catch (error) {
    //eslint-disable-next-line
    console.error("Error getting 3d model files source urls", error);

    return { entities: [] };
  }
};

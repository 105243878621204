import { type Orientation } from "../types";
import { createStore } from "./createStore";

export type OrientationStore = {
  cameraOrientation: Orientation;
  setOrientation: (orientation: Orientation) => void;
};

export const useOrientationStore = createStore<OrientationStore>("OrientationStore", set => ({
  cameraOrientation: "forward",
  setOrientation: (orientation: Orientation) =>
    set(() => ({
      cameraOrientation: orientation,
    })),
}));

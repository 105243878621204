import axios from "axios";

import {
  ParsedQueryValue,
  OrthoviewerDataResponse,
  Group,
  EntityGroup,
  EntityGroupType,
  ParsedData,
  Step,
} from "../types";
import { remapGroupEntityFactory, handleSliderIndex } from "../utils";

/** Api for unprotected routes */
const api = axios.create({
  timeout: 2500,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const getModels = async (
  groupId: ParsedQueryValue
): Promise<{ groups: Group; currentGroup: number; xmlData: ParsedData }> => {
  try {
    const response = await api.get<OrthoviewerDataResponse>(groupId as string);
    const {
      data: { currentGroup, modelType, groups, xmlData },
    } = response.data;

    const mapper = remapGroupEntityFactory(modelType);

    const mandibleUrl = xmlData[0]?.url || "";
    const maxillaUrl = xmlData[1]?.url || "";

    const processXML = async (url: string): Promise<Step[]> => {
      try {
        const xmlResponse = await api.get(url);
        const xmlText = await xmlResponse.data;

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "application/xml");

        const collisionsNode = xmlDoc.querySelector("collisions");
        const stepNodes = collisionsNode ? collisionsNode.querySelectorAll("step") : [];

        return Array.from(stepNodes).map(stepNode => {
          const stepIndex = parseInt(stepNode.getAttribute("number") || "1") - 1;
          const collisionPoints = Array.from(stepNode.querySelectorAll("collision_point")).map(collisionPoint => ({
            positionA: collisionPoint.getAttribute("positionA") || "",
            positionB: collisionPoint.getAttribute("positionB") || "",
            penetration: parseFloat(collisionPoint.getAttribute("penetration") || "0"),
          }));

          return {
            stepIndex,
            collisionPoints,
          };
        });
      } catch (err) {
        throw new Error("Error processing XML");
      }
    };

    const mandibleData = await processXML(mandibleUrl);
    const maxillaData = await processXML(maxillaUrl);

    return {
      currentGroup: handleSliderIndex(currentGroup),
      groups: groups
        .map((g, i, ar) => {
          if (i == 0) return g;
          if (!g.mandible && ar[i - 1].mandible) g.mandible = { ...ar[i - 1].mandible };
          if (!g.maxilla && ar[i - 1].maxilla) g.maxilla = { ...ar[i - 1].maxilla };
          return g;
        })
        .reduce((accum, { mandible, maxilla }, idx) => {
          const entityGroups: EntityGroup[] = [];

          if (mandible) {
            if (mandible[0]) entityGroups.push(mapper(mandible[0], EntityGroupType.MANDIBLE));
            if (mandible[1]) entityGroups.push(mapper(mandible[1], EntityGroupType.MANDIBLE));
          }
          if (maxilla) {
            if (maxilla[0]) entityGroups.push(mapper(maxilla[0], EntityGroupType.MAXILLA));
            if (maxilla[1]) entityGroups.push(mapper(maxilla[1], EntityGroupType.MAXILLA));
          }

          return {
            ...accum,
            [idx]: entityGroups,
          };
        }, {}),
      xmlData: {
        mandible: mandibleData,
        maxilla: maxillaData,
      },
    };
  } catch (error) {
    //eslint-disable-next-line
    console.error("Error getting 3d model files source urls", error);

    return { currentGroup: 0, groups: {}, xmlData: { mandible: [], maxilla: [] } };
  }
};

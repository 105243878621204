import { FC, useRef, useLayoutEffect } from "react";

import { Box3, Vector3, Group } from "three";

const box3 = new Box3();
const center = new Vector3();

const ModelPositioner: FC = ({ children }) => {
  const outerRef = useRef<Group>(null!);
  const innerRef = useRef<Group>(null!);

  useLayoutEffect(() => {
    box3.setFromObject(innerRef.current, true);
    box3.getCenter(center);
    innerRef.current.position.sub(center);
  });

  return (
    <group ref={outerRef}>
      <group ref={innerRef}>{children}</group>
    </group>
  );
};

export default ModelPositioner;

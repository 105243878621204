import { Vector3 } from "three";

import OverlapSphere from "../components/EntityRenderers/components/OverlapSphere";
import TextBox from "../components/EntityRenderers/components/TextBox";
import { MILLIMETERS_SUFFIX } from "../constants";
import { Step } from "../types";

const getPenetrationDisplayText = (penetration: number) => Math.abs(penetration).toFixed(2).concat(MILLIMETERS_SUFFIX);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dataRenderer = (data: Step[], currentGroupIndex: number, prefix: string) => {
  return data.map(step => {
    if (step.stepIndex !== currentGroupIndex) return null;

    return step.collisionPoints.map(({ positionA, positionB, penetration }, index) => {
      const [x1, y1, z1] = positionA.split(" ").map(Number);
      const [x2, y2, z2] = positionB.split(" ").map(Number);
      const prefixKey = `${prefix}-${index}`;

      const firstPoint = new Vector3(x1, y1, z1);

      if (penetration < 0) {
        const secondPoint = new Vector3(x2, y2, z2);

        const center = new Vector3().addVectors(firstPoint, secondPoint).divideScalar(2);
        const radius = Math.max(new Vector3().subVectors(firstPoint, secondPoint).length() / 2, 1);

        const offset = new Vector3(0, 1, 0).multiplyScalar(radius);

        return (
          <group key={prefixKey}>
            <OverlapSphere position={center} radius={radius} />
            <TextBox
              text={getPenetrationDisplayText(penetration)}
              position={firstPoint}
              offset={offset}
              color="yellow"
            />
          </group>
        );
      } else {
        return (
          <TextBox key={prefixKey} text={getPenetrationDisplayText(penetration)} position={firstPoint} color="blue" />
        );
      }
    });
  });
};

import { FC } from "react";

import { MainScene } from "../";
import { EntityObject, EntityType } from "../../types";
import { ObjRenderer, PlyRenderer, StlRenderer } from "./components";

type Props = {
  currentFiles: EntityObject[];
  currentFileIndex: number;
};

const EntityRenderer: FC<Props> = ({ currentFiles, currentFileIndex }) => {
  return (
    <MainScene>
      {currentFiles.map(({ type, ...entity }, index) => {
        const isVisible = index === currentFileIndex;

        if (type === EntityType.OBJ) {
          return <ObjRenderer key={index} {...entity} isVisible={isVisible} />;
        }

        if (type === EntityType.PLY) {
          return <PlyRenderer key={index} {...entity} isVisible={isVisible} />;
        }

        if (type === EntityType.STL) {
          return <StlRenderer key={index} {...entity} isVisible={isVisible} />;
        }
      })}
    </MainScene>
  );
};

export default EntityRenderer;

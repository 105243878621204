import { CameraType } from "../types";
import { createStore } from "./createStore";

export type CameraStore = {
  cameraType: CameraType;
  setCameraType: (type: CameraType) => void;
};

export const useCameraStore = createStore<CameraStore>("CameraStore", set => ({
  cameraType: CameraType.Perspective,
  setCameraType: type =>
    set(() => ({
      cameraType: type,
    })),
}));

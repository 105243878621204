import { useMemo } from "react";

import queryString, { ParsedQuery, ParseOptions } from "query-string";
import { useLocation } from "react-router-dom";

/**
 * Hook that retrieves data from url search params
 * @param options "query-string" parse configuration
 * @returns object with data from url query params
 */

const useURLQueryParams = (options: ParseOptions = { arrayFormat: "comma" }): ParsedQuery => {
  const { search } = useLocation();
  const result = useMemo(() => queryString.parse(search, options), [search]);
  return result;
};

export default useURLQueryParams;

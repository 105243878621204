import { useRef, useEffect, useMemo, useCallback, createContext, useContext } from "react";

import { OrthographicCamera, useCamera } from "@react-three/drei";
import { createPortal, useFrame, useThree } from "@react-three/fiber";
import { Camera, Group, Intersection, Object3D, Quaternion, Raycaster, Scene, Vector3 } from "three";
import { OrbitControls as OrbitControlsType } from "three-stdlib";

type RotationWidgetContext = {
  tweenCamera: (direction: Vector3) => void;
  raycast: (raycaster: Raycaster, intersects: Intersection[]) => void;
};

const Context = createContext<RotationWidgetContext>({} as RotationWidgetContext);

export const useGizmoContext = (): RotationWidgetContext => {
  return useContext<RotationWidgetContext>(Context);
};

const turnRate = 2 * Math.PI; // turn rate in angles per second
const dummy = new Object3D();
const [q1, q2] = [new Quaternion(), new Quaternion()];
const target = new Vector3();
const targetPosition = new Vector3();
const virtualScene = new Scene();

type ControlsProto = { update(): void; target: THREE.Vector3 };

export type RotationWidgetProviderProps = JSX.IntrinsicElements["group"] & {
  alignment?:
    | "top-left"
    | "top-right"
    | "bottom-right"
    | "bottom-left"
    | "bottom-center"
    | "center-right"
    | "center-left"
    | "center-center"
    | "top-center";
  margin?: [number, number];
  renderPriority?: number;
  autoClear?: boolean;
  onUpdate?: () => void; // update controls during animation
};

const isOrbitControls = (controls: ControlsProto): controls is OrbitControlsType => {
  return "minPolarAngle" in (controls as OrbitControlsType);
};

export const RotationWidgetProvider = ({
  alignment = "bottom-right",
  margin = [120, 120],
  renderPriority = 0,
  autoClear = true,
  onUpdate,
  children: ViewportComponent,
}: // eslint-disable-next-line
RotationWidgetProviderProps): any => {
  const { size, gl, invalidate, camera: mainCamera } = useThree();
  // @ts-expect-error new in @react-three/fiber@7.0.5
  const defaultControls = useThree(({ controls }) => controls) as ControlsProto;

  const gizmoRef = useRef<Group>();
  const virtualCam = useRef<Camera>(null!);
  const animating = useRef(false);
  const radius = useRef(0);
  const focusPoint = useRef(new Vector3(0, 0, 0));
  const defaultUp = useRef(new Vector3(0, 0, 0));

  const tweenCamera = useCallback(
    (direction: Vector3) => {
      animating.current = true;
      if (defaultControls) focusPoint.current = defaultControls?.target;
      radius.current = mainCamera.position.distanceTo(target);

      // Rotate from current camera orientation
      q1.copy(mainCamera.quaternion);

      // To new current camera orientation
      targetPosition.copy(direction).multiplyScalar(radius.current).add(target);
      dummy.lookAt(targetPosition);
      q2.copy(dummy.quaternion);

      invalidate();
    },
    [defaultControls, mainCamera, invalidate]
  );

  const raycast = useCamera(virtualCam);
  const gizmoHelperContext = useMemo(() => ({ tweenCamera, raycast }), [tweenCamera]);

  useEffect(() => {
    defaultUp.current.copy(mainCamera.up);
  }, [mainCamera]);

  useFrame((_, delta) => {
    if (virtualCam.current && gizmoRef.current) {
      // Animate step
      if (animating.current) {
        const animateOrientation = () => {
          mainCamera.position.set(0, 0, 1).applyQuaternion(q1).multiplyScalar(radius.current).add(focusPoint.current);
          mainCamera.up.set(0, 1, 0).applyQuaternion(q1).normalize();
          mainCamera.quaternion.copy(q1);
          if (onUpdate) onUpdate();
          else if (defaultControls) defaultControls.update();
          invalidate();
        };

        if (q1.angleTo(q2) < 0.01) {
          animating.current = false;
          q1.copy(q2);
          animateOrientation();

          // Orbit controls uses UP vector as the orbit axes,
          // so we need to reset it after the animation is done
          // moving it around for the controls to work correctly
          if (isOrbitControls(defaultControls)) {
            mainCamera.up.copy(defaultUp.current);
          }
        } else {
          const step = delta * turnRate;
          // animate position by doing a slerp and then scaling the position on the unit sphere
          q1.rotateTowards(q2, step);
          // animate orientation
          animateOrientation();
        }
      }

      // Sync Gizmo with main camera orientation
      // matrix.copy(mainCamera.matrix).invert();
      // gizmoRef.current?.quaternion.setFromRotationMatrix(matrix);

      // Render virtual camera
      if (autoClear) gl.autoClear = false;
      gl.clearDepth();
      gl.render(virtualScene, virtualCam.current);
    }
  }, renderPriority);

  // Position gizmo component within scene
  const [marginX, marginY] = margin;

  const x = alignment.endsWith("-center")
    ? 0
    : alignment.endsWith("-left")
    ? -size.width / 2 + marginX
    : size.width / 2 - marginX;
  const y = alignment.startsWith("center-")
    ? 0
    : alignment.startsWith("top-")
    ? size.height / 2 - marginY
    : -size.height / 2 + marginY;

  return createPortal(
    <Context.Provider value={gizmoHelperContext}>
      <OrthographicCamera ref={virtualCam} position={[0, 0, 200]} />
      <group ref={gizmoRef} position={[x, y, 0]}>
        {ViewportComponent}
      </group>
    </Context.Provider>,
    virtualScene
  );
};

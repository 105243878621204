import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useEntitiesStore, EntitiesStore } from "../../store";
import Row from "./components/Row";
import "./MeasurementPanel.scss";

const selectedMeasuresSelector = (state: EntitiesStore) => state.selectedMeasures;

const MeasurementPanel: FC = () => {
  const { t } = useTranslation();

  const selectedMeasures = useEntitiesStore(selectedMeasuresSelector);

  return (
    <div className="panel-measurement">
      {selectedMeasures ? (
        <>
          <div className="panel-measurement__data">
            <Row label={t("panels:measurementData.min")} data={selectedMeasures.min} />
            <Row label={t("panels:measurementData.max")} data={selectedMeasures.max} />
            <Row label={t("panels:measurementData.size")} data={selectedMeasures.size} />
            <Row label={t("panels:measurementData.center")} data={selectedMeasures.center} />
          </div>
          <div className="panel-measurement__footer">
            <div className="label">{t("panels:measurementWarning")}</div>
            <div>{t("panels:measurementWarningText")}</div>
          </div>
        </>
      ) : (
        <div className="panel-measurement_empty">{t("panels:measurementEmpty")}</div>
      )}
    </div>
  );
};

export default MeasurementPanel;

/* eslint-disable no-console */
import { useEffect, useRef, MutableRefObject } from "react";

import { useThree, useFrame } from "@react-three/fiber";
import { Object3D, BoxHelper } from "three";

import { BOUNDING_BOX_COLOR } from "../constants";
import { useMainSceneStore, MainSceneStore } from "../store";

const boundingBoxSelector = (state: MainSceneStore) => state.boundingBoxVisible;

export type UseHandleBoundingBox = (object3D: MutableRefObject<Object3D | undefined>, isVisible?: boolean) => void;

const useHandleBoundingBox: UseHandleBoundingBox = (object3D, isVisible = true) => {
  const showBBox = useMainSceneStore(boundingBoxSelector);

  const helper = useRef<BoxHelper>();
  const scene = useThree(state => state.scene);

  useEffect(() => {
    if (object3D.current && isVisible && showBBox) {
      helper.current = new BoxHelper(object3D.current, BOUNDING_BOX_COLOR);

      if (helper.current) {
        scene.add(helper.current);
      }
    }

    return () => {
      if (helper.current && isVisible && showBBox) {
        scene.remove(helper.current);
      }
    };
  }, [scene, object3D.current, isVisible, showBBox]);

  useFrame(() => {
    if (helper.current && helper.current.update) {
      helper.current.update();
    }
  });
};

export default useHandleBoundingBox;

import { createStore } from "./createStore";

export type Data = {
  mandible: boolean;
  maxilla: boolean;
};

export type DisplayDataStore = {
  data: Data;
  setData: (data: Partial<Data>) => void;
};

export const useDisplayDataStore = createStore<DisplayDataStore>("DisplayDataStore", set => ({
  data: { mandible: true, maxilla: true },
  setData: data =>
    set(state => ({
      data: {
        ...state.data,
        ...data,
      },
    })),
}));

import { FC, useEffect, useRef } from "react";

import { OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { SCENE_CONFIG } from "../../../constants";
import { useCameraStore } from "../../../store/camera";
import { CameraType } from "../../../types";

const Camera: FC = () => {
  const orthoRef = useRef<THREE.OrthographicCamera>();
  const perspRef = useRef<THREE.PerspectiveCamera>();

  const position = useRef<THREE.Vector3>(SCENE_CONFIG.CAMERA_POSITION.clone());
  const rotation = useRef<THREE.Quaternion>(SCENE_CONFIG.CAMERA_ROTATION.clone());

  const fov = useRef<number>(SCENE_CONFIG.CAMERA_FIELD_OF_VIEW);
  const aspect = useRef<number>(-1);

  const height = useRef<number>(-1);
  const zoom = useRef<number>(SCENE_CONFIG.CAMERA_ZOOM);

  const cameraType = useCameraStore(state => state.cameraType);

  useFrame(() => {
    if (cameraType === CameraType.Perspective) {
      const persp = perspRef.current;
      if (!persp) return;

      position.current.copy(persp.position);
      rotation.current.copy(persp.quaternion);
      fov.current = persp.fov;
      aspect.current = persp.aspect;

      return;
    }

    if (cameraType === CameraType.Orthographic) {
      const ortho = orthoRef.current;
      if (!ortho) return;

      position.current.copy(ortho.position);
      rotation.current.copy(ortho.quaternion);
      height.current = ortho.top * 2;
      zoom.current = ortho.zoom;

      return;
    }
  });

  useEffect(() => {
    if (cameraType === CameraType.Perspective) {
      const persp = perspRef.current;
      if (!persp) return;

      if (height.current < 0) return;

      const fovDegrees = fov.current;
      const fovRadians = THREE.MathUtils.degToRad(fovDegrees);
      const distance = height.current / (2 * Math.tan(fovRadians / 2)) / zoom.current;

      const target = new THREE.Vector3().copy(position.current).normalize().multiplyScalar(distance);
      persp.position.copy(target);

      return;
    }

    if (cameraType === CameraType.Orthographic) {
      const ortho = orthoRef.current;
      if (!ortho) return;

      ortho.zoom = SCENE_CONFIG.CAMERA_ZOOM;

      const fovDegrees = fov.current;
      const fovRadians = THREE.MathUtils.degToRad(fovDegrees);
      const height = 2 * Math.tan(fovRadians / 2) * position.current.length();
      const width = height * aspect.current;

      const heightHalf = (height / 2) * SCENE_CONFIG.CAMERA_ZOOM;
      const widthHalf = (width / 2) * SCENE_CONFIG.CAMERA_ZOOM;

      ortho.bottom = -heightHalf;
      ortho.top = heightHalf;
      ortho.left = -widthHalf;
      ortho.right = widthHalf;

      return;
    }
  }, [cameraType]);

  return (
    <>
      {cameraType === CameraType.Perspective ? (
        <PerspectiveCamera ref={perspRef} position={position.current} quaternion={rotation.current} makeDefault />
      ) : (
        <OrthographicCamera
          ref={orthoRef}
          position={position.current}
          quaternion={rotation.current}
          zoom={zoom.current}
          makeDefault
        />
      )}
    </>
  );
};

export default Camera;

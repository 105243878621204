import { useState, FC } from "react";

import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import "./Panel.scss";

type Props = {
  title: string;
  toggleBtn?: string;
  defaultCollapsed?: boolean;
};

const Panel: FC<Props> = ({ children, title, toggleBtn = "", defaultCollapsed = false }) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const handleToglle = () => setCollapsed(!collapsed);

  return (
    <div className="control-panel">
      <div className="control-panel__header">
        <span className="title">{title}</span>
        <button onClick={handleToglle}>
          {toggleBtn} {!collapsed ? <MdArrowDropUp className="icon" /> : <MdArrowDropDown className="icon" />}
        </button>
      </div>
      {!collapsed && children}
    </div>
  );
};
export default Panel;

import { FC, useLayoutEffect, useState } from "react";
import "./PageLayout.scss";

const PageLayout: FC = ({ children }) => {
  const [height, setHeight] = useState("100vh");

  useLayoutEffect(() => {
    /** Hack to overcome 100hv value not detecting browser's address bar height on mobile device */
    const handleResize = () => {
      setHeight(`${window.innerHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="page-layout-container" style={{ height }}>
      {children}
    </div>
  );
};

export default PageLayout;

import { FC, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { EntityRenderer } from "../components";
import useURLQueryParams from "../hooks/useURLQueryParams";
import { handleSliderIndex, generateEntityArray } from "../utils";
import PageLayout from "./layout/PageLayout";

const HomePage: FC = () => {
  const { currentIndex, files } = useURLQueryParams();
  const currentFileIndexInit = handleSliderIndex(currentIndex as string);
  const currentFiles = generateEntityArray(files);

  const [currentFileIndex, setCurrentFileIndex] = useState(currentFileIndexInit);

  const maxSliderPoint = currentFiles.length - 1;

  if (currentFiles.length === 0) return null;

  return (
    <PageLayout>
      <>
        <EntityRenderer currentFiles={currentFiles} currentFileIndex={currentFileIndex} />
        {maxSliderPoint > 0 && (
          <div className="slider-container">
            <Slider min={0} max={maxSliderPoint} defaultValue={currentFileIndex} onChange={setCurrentFileIndex} />
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default HomePage;

import { FC, ReactNode, memo } from "react";

type Props = {
  reverseContent?: boolean;
  children?: ReactNode;
};

const Row: FC<Props> = ({ children, reverseContent }) => (
  <div className={`stepper__scale-row${reverseContent ? " reversed" : ""}`}>{children}</div>
);

export default memo(Row);

import { createStore } from "./createStore";

export type MainSceneStore = {
  axesVisible: boolean;
  setAxesVisible: (visible: boolean) => void;
  gridVisible: boolean;
  setGridVisible: (visible: boolean) => void;
  polarGridVisible: boolean;
  setPolarGridVisible: (visible: boolean) => void;
  boundingBoxVisible: boolean;
  setBoundingBoxVisible: (visible: boolean) => void;
  arcballControlsEnabled: boolean;
  setArcballControlsEnabled: (enabled: boolean) => void;
};

export const useMainSceneStore = createStore<MainSceneStore>("MainSceneStore", set => ({
  axesVisible: false,
  setAxesVisible: visible => set(state => ({ ...state, axesVisible: visible })),
  gridVisible: false,
  setGridVisible: visible => set(state => ({ ...state, gridVisible: visible })),
  polarGridVisible: false,
  setPolarGridVisible: visible => set(state => ({ ...state, polarGridVisible: visible })),
  boundingBoxVisible: false,
  setBoundingBoxVisible: visible => set(state => ({ ...state, boundingBoxVisible: visible })),
  arcballControlsEnabled: true,
  setArcballControlsEnabled: enabled => set(state => ({ ...state, arcballControlsEnabled: enabled })),
}));

import { MutableRefObject, LegacyRef, RefCallback } from "react";

import { Intersection } from "three";

const colors = [
  "#8EB897",
  "#A2A392",
  "#A4BEF3",
  "#AED4E6",
  "#B2FFA8",
  "#C4AACA",
  "#D7A6B3",
  "#DBD8F0",
  "#E9F5F9",
  "#EEF1DD",
  "#F1DABF",
  "#F4D06F",
  "#FB9F89",
  "#FFFBFF",
  "#F3FFBD",
  "#FE95BB",
  "#94FFBB",
  "#85F6FE",
  "#86AAFE",
  "#D1ADFF",
  "#FFA8A8",
  "#10CAFE",
];

export const randomHexNumber = (): string => colors[Math.floor(Math.random() * colors.length)];

export const handleSliderIndex = (routeIndex?: string | number): number => {
  if (!routeIndex || isNaN(+routeIndex) || +routeIndex <= 0) {
    return 0;
  }
  return +routeIndex - 1;
};

/**
 *
 * @param intersections three.js intersection array
 * @returns
 * Returns the closest intersection to the origin of the Raycaster's ray or empty array.
 *
 * We can use it as Raycaster filter, when composite object is clicked,
 * onClick is fired only once for the closest intersected surface
 */
export const intersectionsFilter = (intersections: Intersection[]): Intersection[] =>
  intersections?.length ? [intersections[0]] : intersections;

export const mergeRefs = <T = unknown>(refs: Array<MutableRefObject<T> | LegacyRef<T>>): RefCallback<T> => {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
};

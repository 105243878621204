import { useMemo } from "react";

import { useThree } from "@react-three/fiber";
import { Box3, Vector3 } from "three";

import { useEntitiesStore, EntitiesStore } from "../store";
import { Object3DMeasures } from "../types";

const box3 = new Box3();
const center = new Vector3();
const size = new Vector3();

const selectedEntitySelector = (state: EntitiesStore) => state.selected;

const useObject3DMeasures = (): Object3DMeasures => {
  const scene = useThree(state => state.scene);
  const selectedEntityName = useEntitiesStore(selectedEntitySelector);

  const result = useMemo(() => {
    if (!selectedEntityName) {
      return null;
    }

    const object3D = scene.getObjectByName(selectedEntityName);
    if (!object3D) {
      return null;
    }

    box3.setFromObject(object3D);
    box3.getCenter(center);
    box3.getSize(size);

    return { min: box3.min, max: box3.max, center, size };
  }, [selectedEntityName]);

  return result;
};

export default useObject3DMeasures;

import { FC, ChangeEventHandler } from "react";

import { useTranslation } from "react-i18next";

import { useMainSceneStore, MainSceneStore } from "../../store";
import "./ViewControls.scss";

const getHandler = (
  cb: (state: boolean, ...args: never[]) => void,
  ...args: never[]
): ChangeEventHandler<HTMLInputElement> => {
  return e => cb(e.target.checked, ...args);
};

const axesSelector = (state: MainSceneStore) => state.axesVisible;
const gridSelector = (state: MainSceneStore) => state.gridVisible;
const boundingBoxSelector = (state: MainSceneStore) => state.boundingBoxVisible;

const axesActionSelector = (actions: MainSceneStore) => actions.setAxesVisible;
const gridActionSelector = (actions: MainSceneStore) => actions.setGridVisible;
const boundingBoxActionSelector = (actions: MainSceneStore) => actions.setBoundingBoxVisible;

const ViewControls: FC = () => {
  const { t } = useTranslation();

  const showAxes = useMainSceneStore(axesSelector);
  const showGrid = useMainSceneStore(gridSelector);
  const showBBox = useMainSceneStore(boundingBoxSelector);

  const setAxesVisible = useMainSceneStore(axesActionSelector);
  const setGridVisible = useMainSceneStore(gridActionSelector);
  const setBoundingBoxVisible = useMainSceneStore(boundingBoxActionSelector);

  return (
    <div className="view-controls">
      <input
        id="show-grid"
        type="checkbox"
        className="view-controls__control"
        onChange={getHandler(setGridVisible)}
        defaultChecked={showGrid}
      />
      <div className="view-controls__label">
        <label htmlFor="show-grid">{t("panels:view.grid")}</label>
      </div>

      <input
        id="show-axes"
        type="checkbox"
        className="view-controls__control"
        onChange={getHandler(setAxesVisible)}
        defaultChecked={showAxes}
      />
      <div className="view-controls__label">
        <label htmlFor="show-axes">{t("panels:view.axes")}</label>
      </div>

      {false && (
        /**
         *  This functionality will be placed on some other place
         *  TODO => find out where
         */
        <>
          <input
            id="show-bbox"
            type="checkbox"
            className="view-controls__control"
            onChange={getHandler(setBoundingBoxVisible)}
            defaultChecked={showBBox}
          />
          <div className="view-controls__label">
            <label htmlFor="show-bbox">Show 3D model bounding box</label>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewControls;

import { memo, useRef, FC, ChangeEventHandler } from "react";

import { ImBin2, ImEye, ImEyeBlocked } from "react-icons/im";
import { MdAddCircleOutline } from "react-icons/md";

import { OpacityEntity } from "../../../types";
import { generateBlobOpacityEntityArray } from "../../../utils";

type Props = {
  allHidden: boolean;
  setOpacityEntities: (data: OpacityEntity[], append?: boolean) => void;
  toggleAll: () => void;
  removeAll: () => void;
};

const UploadControl: FC<Props> = ({ allHidden, setOpacityEntities, toggleAll, removeAll }) => {
  const fileInputRef = useRef<HTMLInputElement>(null!);

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = ({ target: { files } }) => {
    const result = generateBlobOpacityEntityArray(files);
    setOpacityEntities(result, true);
    /** We clear the input so that next time we load the same file onChange handler is called */
    fileInputRef.current.value = "";
  };

  return (
    <div className="file-panel__upload-container">
      <label htmlFor="file-upload">
        <input
          ref={fileInputRef}
          id="file-upload"
          type="file"
          accept=".obj,.stl"
          multiple
          onChange={handleFileUpload}
        />
        <MdAddCircleOutline />
      </label>

      <button className="icon" onClick={removeAll}>
        <ImBin2 />
      </button>
      <button className={`icon ${allHidden ? "" : "active"}`} onClick={toggleAll}>
        {allHidden ? <ImEyeBlocked /> : <ImEye />}
      </button>
    </div>
  );
};
export default memo(UploadControl);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en/translations.json";

export const resources = {
  en,
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === "development",
});

export default i18n;

import { FC, memo } from "react";

import { useEntitiesStore, EntitiesStore } from "../../store";
import { colorAssigner } from "../../utils";
import { SliderControl, UploadControl } from "./components";

import "./OpacityPanel.scss";

const entitiesSelector = (state: EntitiesStore) => state.opacityEntities;
const entitiesActionSelector = (state: EntitiesStore) => state.setOpacityEntities;
const opacityActionSelector = (state: EntitiesStore) => state.updateOpacityEntity;
const selectedEntitySelector = (state: EntitiesStore) => state.selected;
const setSelectedActionSelector = (state: EntitiesStore) => state.setSelectedEntity;
const removeEntityActionSelector = (state: EntitiesStore) => state.removeOpacityEntity;

const toggleAllActionSelector = (state: EntitiesStore) => state.toggleAllEntities;
const removeAllActionSelector = (state: EntitiesStore) => state.removeAllEntities;
const allHiddenSelector = (state: EntitiesStore) => state.getAllHidden();

const OpacityPanel: FC = () => {
  const opacityEntities = useEntitiesStore(entitiesSelector);
  const setOpacityEntities = useEntitiesStore(entitiesActionSelector);
  const setOpacity = useEntitiesStore(opacityActionSelector);
  const selectedEntity = useEntitiesStore(selectedEntitySelector);
  const setSelected = useEntitiesStore(setSelectedActionSelector);
  const removeOpacityEntity = useEntitiesStore(removeEntityActionSelector);

  const allHidden = useEntitiesStore(allHiddenSelector);
  const toggleAll = useEntitiesStore(toggleAllActionSelector);
  const removeAll = useEntitiesStore(removeAllActionSelector);

  const getOpacityHandler = (url: string) => (opacity: number) => {
    setOpacity(url, opacity);
  };

  const getRemoveEntityHandler = (url: string) => () => {
    removeOpacityEntity(url);
    colorAssigner.release(url);
  };

  const handleRemoveAll = () => {
    removeAll();
    colorAssigner.rreleaseAll();
  };

  const getFileSelectHandler = (fileName: string) => () => {
    setSelected(fileName);
  };

  return (
    <div className="file-panel">
      {opacityEntities.map(({ url, name, opacity }) => (
        <SliderControl
          key={url}
          fileName={name}
          opacity={opacity}
          selected={selectedEntity === name}
          setOpacity={getOpacityHandler(url)}
          removeEntity={getRemoveEntityHandler(url)}
          selectFile={getFileSelectHandler(name)}
        />
      ))}
      <UploadControl
        allHidden={allHidden}
        setOpacityEntities={setOpacityEntities}
        toggleAll={toggleAll}
        removeAll={handleRemoveAll}
      />
    </div>
  );
};

export default memo(OpacityPanel);

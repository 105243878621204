import { FC, ReactNode } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ROUTES } from "../../constants";
import { GroupPage, HomePage, OpacityControlPage } from "../../pages";
import StepperContextProvider from "../Stepper/context/StepperContextProvider";

type CustomRoute = {
  path: string;
  component: ReactNode;
  title: string;
  exact?: boolean;
};

export const routes: CustomRoute[] = [
  {
    path: ROUTES.HOME,
    component: <HomePage />,
    title: "Go to: Multiple Files",
    exact: true,
  },
  {
    path: ROUTES.ORTHO_VIEWER,
    component: (
      <StepperContextProvider>
        <GroupPage />
      </StepperContextProvider>
    ),
    title: "Go to: Group multi-files",
    exact: true,
  },
  {
    path: ROUTES.FILE_VIEWER,
    title: "Go to: Opacity Controls",
    component: <OpacityControlPage />,
    exact: true,
  },
];

const Router: FC = () => (
  <BrowserRouter>
    <Switch>
      {routes.map(({ path, component, exact }) => (
        <Route key={path} exact={exact} path={path}>
          {component}
        </Route>
      ))}
    </Switch>
  </BrowserRouter>
);

export default Router;

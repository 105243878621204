import { useState, useMemo } from "react";

import { useThree, ThreeEvent } from "@react-three/fiber";
import { CanvasTexture } from "three";

import { type Orientation } from "../../types";

type WidgetButtonProps = JSX.IntrinsicElements["sprite"] & {
  arcColor: string;
  label?: string;
  labelColor: string;
  font: string;
  name: Orientation;
};

const CANVAS_SIDE = 100;
const CANVAS_CENTER = CANVAS_SIDE / 2;

const WidgetButton = ({ font, arcColor, label, labelColor, name, ...props }: WidgetButtonProps): JSX.Element => {
  const gl = useThree(state => state.gl);
  const texture = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = CANVAS_SIDE;
    canvas.height = CANVAS_SIDE;

    const context = canvas.getContext("2d")!;
    context.beginPath();

    context.font = font;
    context.textAlign = "center";
    context.fillStyle = labelColor;
    context.lineCap = "round";
    context.lineWidth = 4;
    context.strokeStyle = arcColor;

    if (name === "right") {
      // Right view
      context.ellipse(20, CANVAS_CENTER, 70, 40, 0, -90 * (Math.PI / 180), 90 * (Math.PI / 180));
      label && context.fillText(label, 30, CANVAS_CENTER + 6);
    } else if (name === "left") {
      // left view
      context.ellipse(80, CANVAS_CENTER, 70, 40, 0, -90 * (Math.PI / 180), 90 * (Math.PI / 180), true);
      label && context.fillText(label, 70, CANVAS_CENTER + 6);
    } else if (name === "up") {
      // Upper view
      context.ellipse(CANVAS_CENTER, 80, 40, 70, 0, -180 * (Math.PI / 180), Math.PI / 180);
      label && context.fillText(label, CANVAS_CENTER, 70);
    } else if (name === "down") {
      // Lower view
      context.ellipse(CANVAS_CENTER, 20, 40, 70, 0, -180 * (Math.PI / 180), Math.PI / 180, true);
      label && context.fillText(label, CANVAS_CENTER, 40);
    } else if (name === "forward") {
      // Front view
      context.ellipse(CANVAS_CENTER, 25, 40, 20, 0, -180 * (Math.PI / 180), Math.PI / 180, true);
      context.stroke();
      context.beginPath();
      context.ellipse(CANVAS_CENTER, 75, 40, 20, 0, -180 * (Math.PI / 180), Math.PI / 180);
    }

    context.stroke();

    return new CanvasTexture(canvas);
  }, [arcColor, name, labelColor, font, label]);

  const [buttonScale, setButtonScale] = useState(1);

  const handlePointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    document.body.style.cursor = "pointer";
    setButtonScale(1.1);
  };
  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    document.body.style.cursor = "default";
    setButtonScale(1);
  };

  return (
    <sprite
      scale={buttonScale}
      name={name}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      {...props}
    >
      <spriteMaterial
        map={texture}
        map-encoding={gl.outputEncoding}
        map-anisotropy={gl.capabilities.getMaxAnisotropy() || 1}
        alphaTest={0.3}
        toneMapped={false}
      />
    </sprite>
  );
};

export default WidgetButton;

import { FC } from "react";

import { Html, useProgress } from "@react-three/drei";

import "./Progress.scss";

const Progress: FC = () => {
  const { progress } = useProgress();

  return (
    <Html center className="progress">
      Loading... {progress.toFixed(0)}%
    </Html>
  );
};

export default Progress;

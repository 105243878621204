import { FC } from "react";

import { GROUP_ROTATION } from "../../../constants";
import { useDisplayDataStore } from "../../../store/displayData";
import { ParsedData } from "../../../types";
import { dataRenderer } from "../../../utils/dataRenderer";

type DisplayDataProps = {
  xmlData: ParsedData;
  currentGroupIndex: number;
};

const DataDisplay: FC<DisplayDataProps> = ({ xmlData, currentGroupIndex }) => {
  const { data } = useDisplayDataStore();

  return (
    <group quaternion={GROUP_ROTATION}>
      {data.mandible && dataRenderer(xmlData.mandible, currentGroupIndex, "mandible")}
      {data.maxilla && dataRenderer(xmlData.maxilla, currentGroupIndex, "maxilla")}
    </group>
  );
};

export default DataDisplay;

import { FC } from "react";

import { Vector3 } from "three";

import { useMainSceneStore, MainSceneStore } from "../../../store";
import GridHelper from "./GridHelper";

type Props = {
  size?: number;
};

const axesSelector = (state: MainSceneStore) => state.axesVisible;
const gridSelector = (state: MainSceneStore) => state.gridVisible;
const polarGridSelector = (state: MainSceneStore) => state.polarGridVisible;

const DimensionHelpers: FC<Props> = ({ size = 10 }) => {
  const showAxes = useMainSceneStore(axesSelector);
  const showGrid = useMainSceneStore(gridSelector);
  const showPolarGrid = useMainSceneStore(polarGridSelector);

  if (!showAxes && !showGrid && !showPolarGrid) {
    return null;
  }

  return (
    <group>
      {showAxes && (
        <>
          <arrowHelper args={[new Vector3(1, 0, 0), new Vector3(0, 0, 0), size, "red", 2, 1]} />
          <arrowHelper args={[new Vector3(0, 1, 0), new Vector3(0, 0, 0), size, "green", 2, 1]} />
          <arrowHelper args={[new Vector3(0, 0, 1), new Vector3(0, 0, 0), size, "blue", 2, 1]} />
        </>
      )}

      {showGrid && (
        <>
          <GridHelper />
          <GridHelper rotation={[Math.PI * 0.5, 0, 0]} />
          <GridHelper rotation={[0, 0, Math.PI * 0.5]} />
        </>
      )}

      {showPolarGrid && (
        <>
          <polarGridHelper args={[45, -1, 1, 64]} />
          <polarGridHelper args={[45, -1, 1, 64]} rotation={[Math.PI * 0.5, 0, 0]} />
          <polarGridHelper args={[45, -1, 1, 64]} rotation={[0, 0, Math.PI * 0.5]} />
        </>
      )}
    </group>
  );
};

export default DimensionHelpers;

import { FC } from "react";

import { Vector3 } from "three";

const handleText = (input: number): string => input.toFixed(4);

const Row: FC<{ label: string; data: Vector3 }> = ({ label, data: { x, y, z } }) => (
  <>
    <div className="label">{label}</div>
    <div>{handleText(x)}</div>
    <div>{handleText(y)}</div>
    <div>{handleText(z)}</div>
  </>
);

export default Row;

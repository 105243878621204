import { useEffect, useRef, forwardRef } from "react";

import { ArcballControls, ArcballControlsProps } from "@react-three/drei";
import { ArcballControls as ArcballControlsImpl } from "three-stdlib";

import { SCENE_CONFIG } from "../../../constants";
import { mergeRefs } from "../../../utils";

const Controls = forwardRef<ArcballControlsImpl, ArcballControlsProps>((props, innerRef) => {
  const controlsRef = useRef<ArcballControlsImpl>();

  useEffect(() => {
    if (controlsRef.current) {
      /**
       * I wasn't able to overcome the below TypeScript error:
       * Property 'setMouseAction' is private and only accessible within class 'ArcballControls'.
       */
      //@ts-ignore
      controlsRef.current.setMouseAction("ROTATE", 0, "CTRL");
      //@ts-ignore
      controlsRef.current.setMouseAction("PAN", 1);
      //@ts-ignore
      controlsRef.current.setMouseAction("ZOOM", 2);
    }
  }, [controlsRef.current]);

  return (
    <ArcballControls
      /**
       * ref prop of `ArcballControls` has limitation
       * and doesn't cover passing `RefCallback` type
       */
      //@ts-ignore
      ref={mergeRefs([innerRef, controlsRef])}
      makeDefault
      rotateSpeed={2}
      enableZoom
      enableRotate
      enablePan
      enableAnimations={false}
      minDistance={SCENE_CONFIG.CAMERA_MIN_DISTANCE}
      maxDistance={SCENE_CONFIG.CAMERA_MAX_DISTANCE}
      minZoom={SCENE_CONFIG.CAMERA_MIN_ZOOM}
      maxZoom={SCENE_CONFIG.CAMERA_MAX_ZOOM}
      {...props}
    />
  );
});

export default Controls;

import { FC, MouseEvent, ReactNode } from "react";

type Props = {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  active?: boolean;
  children?: ReactNode;
};

const Dot: FC<Props> = ({ children, active, disabled, ...props }) => (
  <span className={`stepper__dot${active ? " active" : disabled ? " disabled" : ""}`} {...props}>
    {children}
  </span>
);

export default Dot;

import { FC, useMemo } from "react";

import { Color, Euler, BufferGeometry, LineBasicMaterial, Float32BufferAttribute } from "three";

import { GRID_CONFIG } from "../../../constants";

type GridProps = {
  size?: number;
  divisions?: number;
  offset?: number;
  colorMain?: string | Color;
  colorCenter?: string | Color;
  colorOffset?: string | Color;
  border?: boolean;
  rotation?: [number, number, number] | Euler;
};

const GridHelper: FC<GridProps> = ({
  size = GRID_CONFIG.SIZE,
  divisions = GRID_CONFIG.DIVISIONS,
  offset = GRID_CONFIG.OFFSET,
  colorMain = GRID_CONFIG.MAIN_COLOR,
  colorCenter = GRID_CONFIG.CENTER_COLOR,
  colorOffset = GRID_CONFIG.OFFSET_COLOR,
  border = false,
  rotation,
}) => {
  const { geometry, material } = useMemo(() => {
    const geometry = new BufferGeometry();
    const material = new LineBasicMaterial({ vertexColors: true, toneMapped: false });
    colorCenter = new Color(colorCenter);
    colorMain = new Color(colorMain);
    colorOffset = new Color(colorOffset);

    const center = divisions / 2;
    const step = size / divisions;
    const halfSize = size / 2;
    const offsetIndex = halfSize - offset * step;

    const vertices = [];
    const colors: never[] = [];

    for (let i = 0, j = 0, k = -halfSize; i <= divisions; i++, k += step) {
      if (!border && (i === 0 || i === divisions)) {
        continue;
      }

      let color = colorMain;
      if (i === center) {
        color = colorCenter;
      } else if (Math.abs(k) === offsetIndex) {
        color = colorOffset;
      }

      vertices.push(-halfSize, 0, k, halfSize, 0, k);
      vertices.push(k, 0, -halfSize, k, 0, halfSize);

      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
      color.toArray(colors, j);
      j += 3;
    }

    geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    geometry.setAttribute("color", new Float32BufferAttribute(colors, 3));

    return { geometry, material };
  }, [size, divisions, offset, colorMain, colorCenter, colorOffset, border]);

  return <lineSegments args={[geometry, material]} rotation={rotation} />;
};

export default GridHelper;

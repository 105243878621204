import { createContext, useState, FC, Dispatch, SetStateAction, useContext } from "react";

type StepperContextValue = {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  totalSteps: number;
  setTotalSteps: Dispatch<SetStateAction<number>>;
};

const StepperContext = createContext<StepperContextValue>(null!);

export const useStepperContext = (): StepperContextValue => useContext(StepperContext);

const StepperContextProvider: FC = ({ children }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);

  return (
    <StepperContext.Provider value={{ stepIndex, setStepIndex, totalSteps, setTotalSteps }}>
      {children}
    </StepperContext.Provider>
  );
};

export default StepperContextProvider;

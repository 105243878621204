import { Dispatch, SetStateAction, useState, useCallback, useRef, useEffect } from "react";

/**
 * Hook that hanldes a function execution in interval loop
 * @param callback function to invoke per interval
 * @param delay interval delay
 * @returns a flag indicating is interval running or not, a function to start/stop interval
 */

export type IntervalLoopCallback = (cb: Dispatch<SetStateAction<boolean>>) => void;

const useIntervalLoop = (callback: IntervalLoopCallback, delay = 500): [boolean, () => void] => {
  const [playing, setPlaying] = useState(false);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (playing) {
      const intervalId = setInterval(() => {
        callbackRef.current(setPlaying);
      }, delay);
      return () => clearInterval(intervalId);
    }
  }, [playing]);

  const togglePlaying = useCallback(() => setPlaying(!playing), [playing]);

  return [playing, togglePlaying];
};

export default useIntervalLoop;

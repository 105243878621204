import { FC } from "react";

import { MeshBasicMaterial, Vector3 } from "three";

export type OverlapSphereProps = {
  position: Vector3;
  radius: number;
};

const sphereMat = new MeshBasicMaterial({ color: 0xff0000 });

const OverlapSphere: FC<OverlapSphereProps> = ({ position, radius }) => {
  return (
    <mesh renderOrder={1} material={sphereMat} position={position}>
      <sphereGeometry args={[radius]} />
    </mesh>
  );
};

export default OverlapSphere;

import { Group, ParsedData } from "../types";
import { createStore } from "./createStore";

export type GroupsStore = {
  entityGroups: Group;
  xmlData: ParsedData;
  setEntityGroups: (groups: Group) => void;
  setXmlData: (data: ParsedData) => void;
};

export const useGroupsStore = createStore<GroupsStore>("GroupsStore", set => ({
  entityGroups: {},
  xmlData: { mandible: [], maxilla: [] },
  setEntityGroups: data =>
    set(() => ({
      entityGroups: data,
    })),
  setXmlData: data =>
    set(() => ({
      xmlData: data,
    })),
}));

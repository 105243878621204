import { memo, FC } from "react";

import Slider from "rc-slider";
import { ImBin2, ImEye, ImEyeBlocked } from "react-icons/im";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";

type Props = {
  fileName: string;
  opacity: number;
  selected: boolean;
  removeEntity: () => void;
  setOpacity: (opacity: number) => void;
  selectFile: () => void;
};

const SLIDER_STEP = 0.1;
const SLIDER_MIN = 0;
const SLIDER_MAX = 1;

const SliderControl: FC<Props> = ({ fileName, opacity, selected, setOpacity, removeEntity, selectFile }) => {
  const hidden = opacity === SLIDER_MIN;
  const fullyVisible = opacity === SLIDER_MAX;

  const increaseOpacity = () => setOpacity(Math.min(opacity + SLIDER_STEP, 1));
  const decreaseOpacity = () => setOpacity(Math.max(opacity - SLIDER_STEP, 0));
  const toggleOpacity = () => setOpacity(opacity === 0 ? 1 : 0);

  if (!fileName) {
    return null;
  }

  return (
    <div className="file-panel__entity">
      <p className={`file-panel__entity-name ${selected ? "selected" : ""}`} onClick={selectFile}>
        {fileName}
      </p>
      <div className="file-panel__controls">
        <Slider min={SLIDER_MIN} max={SLIDER_MAX} value={opacity} onChange={setOpacity} step={SLIDER_STEP} />

        <div className="file-panel__opacity-buttons">
          <button onClick={increaseOpacity} disabled={fullyVisible}>
            <MdArrowDropUp />
          </button>
          <button onClick={decreaseOpacity} disabled={hidden}>
            <MdArrowDropDown />
          </button>
        </div>

        <button className="icon" onClick={removeEntity}>
          <ImBin2 />
        </button>
        <button className={`icon ${hidden ? "" : "active"}`} onClick={toggleOpacity}>
          {hidden ? <ImEyeBlocked /> : <ImEye />}
        </button>
      </div>
    </div>
  );
};

export default memo(SliderControl);

import { FC } from "react";

type SceneLightProps = {
  ambient?: number;
  front?: number;
  right?: number;
  left?: number;
  back?: number;
  top?: number;
  bottom?: number;
  lightDistance: number;
};

const SceneLight: FC<SceneLightProps> = ({ ambient, front, right, left, back, top, bottom, lightDistance }) => {
  return (
    <>
      {ambient && <ambientLight intensity={ambient} />}
      {right && <pointLight position={[lightDistance, 0, 0]} castShadow intensity={right} />}
      {left && <pointLight position={[-lightDistance, 0, 0]} castShadow intensity={left} />}
      {top && <pointLight position={[0, lightDistance, 0]} castShadow intensity={top} />}
      {bottom && <pointLight position={[0, -lightDistance, 0]} castShadow intensity={bottom} />}
      {front && <pointLight position={[0, 0, -lightDistance]} castShadow intensity={front} />}
      {back && <pointLight position={[0, 0, lightDistance]} castShadow intensity={back} />}
    </>
  );
};

export default SceneLight;

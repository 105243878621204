import { FC } from "react";

import { SCENE_CONFIG } from "../../constants";
import { RotationWidgetProvider } from "./RotationWidgetContext";
import RotationWidgetViewport from "./RotationWidgetViewport";

const RotationWidget: FC = () => {
  return (
    <RotationWidgetProvider alignment="top-left" margin={SCENE_CONFIG.GIZMO_MARGINS}>
      <RotationWidgetViewport />
    </RotationWidgetProvider>
  );
};

export default RotationWidget;

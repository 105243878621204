import create, { State, StateCreator, UseBoundStore, StoreApi } from "zustand";
import { devtools } from "zustand/middleware";

const isDevelop = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const createStore = <TState extends State>(
  name: string,
  config: StateCreator<TState>
): UseBoundStore<TState, StoreApi<TState>> => {
  const devConfig = isDevelop ? devtools(config, { name }) : config;

  return create<TState>(devConfig);
};

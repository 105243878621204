import { FC } from "react";

type Props = {
  onPrev: () => void;
  onPlay: () => void;
  onNext: () => void;
  hasNext: boolean;
  playing: boolean;
  playBtnText?: string;
  stopButtonText?: string;
};

const Controls: FC<Props> = ({
  onPrev,
  onPlay,
  onNext,
  hasNext,
  playing,
  playBtnText = "play",
  stopButtonText = "stop",
}) => (
  <div className="stepper__controls">
    <button className="stepper__controls-btn-prev" type="button" onClick={onPrev} disabled={playing}>
      &#60;
    </button>
    <button className="stepper__controls-btn-play" type="button" onClick={onPlay} disabled={!hasNext}>
      {playing ? stopButtonText : playBtnText}
    </button>
    <button className="stepper__controls-btn-next" type="button" onClick={onNext} disabled={playing}>
      &#62;
    </button>
  </div>
);

export default Controls;

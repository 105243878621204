import { FC, useEffect, useState } from "react";

import { ThreeEvent } from "@react-three/fiber";

import { WIDGET_DIRECTIONS } from "../../constants";
import { useOrientationStore } from "../../store";
import { type Orientation } from "../../types";
import { useGizmoContext } from "./RotationWidgetContext";
import WidgetButton from "./WidgetButton";

type RotationWidgetViewportProps = JSX.IntrinsicElements["group"] & {
  arcColor?: string;
  labelColor?: string;
  font?: string;
};

const getScreenScale = () => {
  if (window.innerHeight < 320 || window.innerWidth < 768) return 40;

  return 50;
};

const RotationWidgetViewport: FC<RotationWidgetViewportProps> = ({
  font = "22px Inter var, Arial, sans-serif",
  arcColor = "#fff000",
  labelColor = "#fff",
  ...props
}) => {
  const { tweenCamera, raycast } = useGizmoContext();
  const { setOrientation } = useOrientationStore();
  const [screenScale, setScreenScale] = useState(getScreenScale());

  const widgetButtonProps = {
    font,
    labelColor,
    arcColor,
    raycast,
    onPointerDown: (e: ThreeEvent<PointerEvent>) => {
      const orientation = e.object.name as Orientation;
      if (orientation) setOrientation(orientation);
      tweenCamera(WIDGET_DIRECTIONS[orientation]);
      e.stopPropagation();
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenScale(getScreenScale());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <group scale={screenScale} {...props}>
      <WidgetButton position={[1, 0, 0]} label="R" name="right" {...widgetButtonProps} />
      <WidgetButton position={[0, 1, 0]} label="U" name="up" {...widgetButtonProps} />
      <WidgetButton position={[0, 0, 0]} name="forward" {...widgetButtonProps} />
      <WidgetButton position={[-1, 0, 0]} label="L" name="left" {...widgetButtonProps} />
      <WidgetButton position={[0, -1, 0]} label="L" name="down" {...widgetButtonProps} />
    </group>
  );
};

export default RotationWidgetViewport;

import { FC, useRef } from "react";

import { Plane, Text } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { Group, MeshBasicMaterial, Vector3 } from "three";

import { OVERLAY_MESH_BASIC_MATERIAL_SETTINGS } from "../../../constants";

type SupportedColors = "blue" | "yellow";

export type TextBoxProps = {
  position: Vector3;
  text: string;
  color: SupportedColors;
  offset?: Vector3;
};

const outerPlaneMatBlue = new MeshBasicMaterial({ color: 0x0000ff, ...OVERLAY_MESH_BASIC_MATERIAL_SETTINGS });
const outerPlaneMatYellow = new MeshBasicMaterial({ color: 0xffff00, ...OVERLAY_MESH_BASIC_MATERIAL_SETTINGS });
const innerPlaneMat = new MeshBasicMaterial({ color: 0x000000, ...OVERLAY_MESH_BASIC_MATERIAL_SETTINGS });
const textMat = new MeshBasicMaterial({ color: 0xffff00, ...OVERLAY_MESH_BASIC_MATERIAL_SETTINGS });

const getMaterial = (color: SupportedColors): MeshBasicMaterial => {
  if (color === "blue") return outerPlaneMatBlue;

  return outerPlaneMatYellow;
};

const TextBox: FC<TextBoxProps> = ({ position, offset, text, color }) => {
  const { camera } = useThree();
  const groupRef = useRef<Group>(null!);

  useFrame(() => {
    groupRef.current.position.copy(position);
    groupRef.current.up.copy(camera.up);
    groupRef.current.lookAt(camera.position);
  });

  return (
    <group ref={groupRef}>
      <group renderOrder={1} position={offset}>
        <Plane scale={[4.5, 1, 1]} material={getMaterial(color)} />
        <Plane position={[0, 0, 0.1]} scale={[4.2, 0.8, 1]} material={innerPlaneMat} />
        <Text position={[0, 0, 0.2]} material={textMat} fontSize={1} anchorX="center" anchorY={"middle"}>
          {text}
        </Text>
      </group>
    </group>
  );
};

export default TextBox;

import { FC } from "react";

import useIntervalLoop, { IntervalLoopCallback } from "../../hooks/useIntervalLoop";
import { ActiveMarker, Controls, Dot, Row } from "./components";
import { useStepperContext } from "./context/StepperContextProvider";

import "./Stepper.scss";

const STEPS_LIMIT = 120;

const Stepper: FC = () => {
  const { totalSteps, stepIndex, setStepIndex } = useStepperContext();

  const lastItemIndex = (STEPS_LIMIT > totalSteps ? totalSteps : STEPS_LIMIT) - 1;
  const hasNext = stepIndex < lastItemIndex;

  /** Play handler that will be called in `useIntervalLoop` */
  const handlePlay: IntervalLoopCallback = setPlaying => {
    if (!hasNext) return;

    setStepIndex(stepIndex + 1);
    if (stepIndex + 1 === lastItemIndex) {
      setPlaying(false);
    }
  };

  /** Button handlers */
  const [playing, handleTogglePlaying] = useIntervalLoop(handlePlay, 300);

  const handlePrev = () => setStepIndex(Math.max(stepIndex - 1, 0));

  const handleNext = () => setStepIndex(Math.min(stepIndex + 1, lastItemIndex));

  /** Dot renderer */
  const dotGenerator = (val: number, idx: number) => {
    const dataIndex = idx + val;
    const isActive = !!(stepIndex === dataIndex);
    const hasEntity = dataIndex < totalSteps;
    const onClick = () => hasEntity && setStepIndex(dataIndex);

    return (
      <Dot key={dataIndex} active={isActive} onClick={onClick} disabled={!hasEntity}>
        {stepIndex === dataIndex && <ActiveMarker />}
      </Dot>
    );
  };

  return (
    <div className="stepper__container">
      <Row>
        {Array(lastItemIndex + 1)
          .fill(0)
          .map(dotGenerator)}
      </Row>
      <Controls
        onPrev={handlePrev}
        onPlay={handleTogglePlaying}
        onNext={handleNext}
        playing={playing}
        hasNext={hasNext}
      />
    </div>
  );
};

export default Stepper;
